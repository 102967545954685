.home-review {
  display: flex !important;
  justify-content: space-between;
  align-items: center;
}

.review {
  &-image-mobile img {
    display: none;
  }
  &-image {
    img {
      width: 400px;
      height: auto;
    }
  }
  &-content {
    border: 2px dashed $success;
    padding: 30px 10px;
    border-radius: 10px;
  }

  &-section {
    height: 100vh;
    margin: auto;
    align-items: center;
    display: flex;
  }
}

.home-departure-date {
  li {
    list-style: none;
  }
}

@media (max-width: 768px) {
  .home-review {
    display: flex;
    flex-direction: column;
  }

  .review-image img {
    display: none;
  }

  .review {
    &-image-mobile {
      display: flex;
      justify-content: center;
      img {
        display: block;
        height: auto;
        width: 350px;
        margin: auto !important;
      }
    }
    &-section {
      height: auto;
    }
    &-content {
      height: 50vh;
      overflow: auto;
      width: 100% !important;
    }
  }
}
