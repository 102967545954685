@import "../../variables/custom-variables";
.trip-detail {
  color: #000;
  font-size: 18px;
  font-weight: 400;
  p,
  ul,
  li {
    line-height: 2 !important;
    font-family: "Roboto", sans-serif;
    font-size: 18px;
  }
  &-title {
    font-size: 22px;
    font-weight: 800;
    color: $primaryColor;
    font-family: "Roboto", sans-serif;
  }
  &-content {
    font-family: "Roboto", sans-serif;
    font-size: 18px;
    font-weight: 400;
    text-align: justify;
    // letter-spacing: 0px;
    line-height: 1.9;
    color: #444;
    text-size-adjust: 100%;
  }
  .trip {
    &-sidebar {
      position: -webkit-sticky;
      position: sticky;
      top: 50px;
      overflow: scroll !important;
      height: 90vh;
      ul li a {
        font-size: 13px;
      }
      ul {
        list-style-type: none;
        padding: 0;
      }
      li {
        padding: 5px 30px;
      }
      li:hover {
        background: $primaryColor;
        a {
          color: #fff;
        }
      }
      a {
        text-decoration: none;
      }
      // a:hover {
      //   color: #fff;
      // }
      h4 {
        padding-top: 48px;
        color: $danger;
      }
    }
    &-info {
      h4 {
        padding-top: 50px;
      }
    }
  }
}

@media (max-width: 600px) {
  .trip-detail {
    &-title {
      font-size: 22px !important;
      font-weight: 800;
      color: $primaryColor;
      font-family: "Roboto", sans-serif;
    }

    &-content {
      font-size: 18px !important;
      font-family: "Roboto", sans-serif;
      font-weight: 400;
      text-align: justify;
      // letter-spacing: 0px;
      line-height: 1.9;
      color: #444;
      text-size-adjust: 100%;
    }
    ul li {
      font-size: 18px;
    }
  }
}
