.popupcard {
  display: flex;
  justify-content: space-between;

  img {
    width: 100%;
    height: 300px;
    object-fit: cover;
  }

  .card {
    margin-top: 10px !important;
    -webkit-box-shadow: 0 10px 6px -6px #777;
    -moz-box-shadow: 0 10px 6px -6px #777;
    box-shadow: 0 10px 6px -6px #777;
  }
}

@media (max-width: 576px) {
  .popupcard {
    img {
      display: none;
    }
  }
}
