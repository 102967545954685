.article {
  &-section {
    background: $gray-200;
    padding: 50px 0;
    .row {
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }
  }
  &-cards {
    display: flex;
    justify-content: space-between;
    gap: 30px;
    img {
      cursor: pointer;
    }
    .card-title {
      color: #000;
      font-size: 16px;
      &:hover {
        color: $secondaryColor;
      }
    }
    .card-body {
      height: 70px;
      display: flex;
      align-items: center;
    }
    .card-body p {
      display: none;
    }
  }
}

.blog-post {
  &-header {
    background-color: #202222;
    height: 200px;
    text-align: center;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: 30px;
  }
  &-body {
    img {
      width: 100%;
      margin: 30px 0;
    }
  }
}

@media (max-width: 800px) {
  .blog-post {
    &-header {
      justify-content: flex-end !important;
      height: 300px;
      margin-bottom: 0 !important;
    }
  }
}
