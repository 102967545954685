.main-book {
  color: $black;
  .options {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: flex-start;
    width: 100%;
    margin: 10px 0;
    label {
      // width: 150px;
      padding-bottom: 10px;
      font-size: 18px;
      color: #6c757d;
    }
    select {
      background-color: $white;
      color: #bcbec0;
      border: none;
      width: 350px;
      border-bottom: #6c757d solid;
      padding-left: 10px;
      &:focus {
        outline: none;
      }
      // &:hover {
      //   transform: scale(1.05);
      // }
      cursor: pointer;
    }
    .form-required::after {
      content: "*";
      color: $danger;
      padding-left: 5px;
    }
  }
  form {
    display: flex;
    flex-direction: column;
    // align-items: center;
    // justify-content: center;
    width: 100%;
    text-align: start;
    color: #bcbec0;
    .options {
      display: flex;
      flex-direction: column;
      justify-content: start;
      align-items: flex-start;
      width: 100%;
      margin: 10px 0;
      label {
        // width: 150px;
        padding-bottom: 10px;
        font-size: 20px;
        color: #6c757d;
      }
      input {
        background-color: $white;
        color: #bcbec0;
        border: none;
        width: 350px;
        border-bottom: #6c757d solid;
        padding-left: 10px;
        &:focus {
          outline: none;
        }
        // &:hover {
        //   transform: scale(1.05);
        // }
      }
      select {
        background-color: $white;
        color: #bcbec0;
        border: none;
        width: 350px;
        border-bottom: #6c757d solid;
        padding-left: 10px;
        &:focus {
          outline: none;
        }
        // &:hover {
        //   transform: scale(1.05);
        // }
      }

      .input {
        background-color: $white;
        color: $black;
        border: none;
        width: 350px;
        border-bottom: $black solid;
        padding-left: 10px;
        &:focus {
          outline: none;
        }
        // &:hover {
        //   transform: scale(1.05);
        // }
      }
      ::-webkit-calendar-picker-indicator {
        background-image: url(http://icons.iconarchive.com/icons/dakirby309/simply-styled/256/Calendar-icon.png);
        background-position: center;
        background-size: 20px 20px;
        background-repeat: no-repeat;
        color: rgba(204, 204, 204, 0);
      }
    }
    // .btn {
    //   background-color: $white;
    //   border-radius: 8px;
    //   border-style: none;
    //   box-sizing: border-box;
    //   color: $black;
    //   cursor: pointer;
    //   display: inline-block;
    //   font-size: 14px;
    //   font-weight: 500;
    //   height: 40px;
    //   line-height: 20px;
    //   list-style: none;
    //   margin: 0;
    //   outline: none;
    //   padding: 10px 20%;
    //   position: relative;
    //   text-align: center;
    //   text-decoration: none;
    //   vertical-align: baseline;
    //   user-select: none;
    //   -webkit-user-select: none;
    //   touch-action: manipulation;
    //   &:hover,
    //   &:focus {
    //     background-color: $white;
    //     color: $black;
    //   }
    // }
    // .btn-book {
    //   background-color: $black;
    //   color: $white;
    //   padding: 15px;
    //   border: $black solid 1.5px;
    //   border-radius: 8px;
    //   font-size: 20px;
    //   font-weight: 600;
    //   &:hover {
    //     transform: scale(1.05);
    //   }
    // }

    .book-textarea {
      margin: 0 !important;
      width: 100% !important;
      textarea {
        width: 100% !important;
        height: 300px;
        border: 2px solid #6c757d !important;
      }
    }
  }

  .required::after {
    content: "*";
    color: red;
  }

  .booking-detail {
    input,
    select {
      width: 100%;
      background: #dfdfdf;
    }
    textarea {
      height: 100px;
      background: #dfdfdf;
    }
  }

  .booking-sidebar {
    border: 2px solid $black;
    padding: 10px;
    position: sticky;
    top: 0;
    .book-img {
      width: 100%;
      height: 300px;
      object-fit: cover;
    }
    ul {
      padding: 0;
    }
    ul li {
      list-style-type: none;
      line-height: 2.5;
      span {
        padding-left: 30px;
      }
      b {
        position: relative;
        display: inline-block;
        min-width: 140px;
      }
    }
    ul li b::after {
      content: ":";
      color: $black;
      position: absolute;
      display: inline-block;
      right: 0;
    }
  }
}
