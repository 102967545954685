.trip {
  // overflow-y: hidden !important;
  .trip-hero {
    position: relative;
    height: calc(100vh - 50px);
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.35), rgba(0, 0, 0, 0.5)),
      url(../../../image/Everest/EBCTrek/8.jpg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    .trip-box {
      height: 100vh;
      width: 100%;
      position: absolute;
      top: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      .trip-detail {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        border: 2px solid $white;
        color: $black;
        width: 20vw;
        background: linear-gradient(
          0deg,
          rgba(0, 0, 0, 0.4),
          rgba(0, 0, 0, 0.4)
        );
        // height: 25vh;
        // padding: 20px 20px 0 20px;
        .main-title {
          font-family: "Roboto", sans-serif;
          font-size: 1.5rem;
          letter-spacing: 1px;
          font-weight: 400;
          text-align: center;
          color: $white;
        }
        .activity {
          p {
            font-family: "Roboto", sans-serif;
            margin-bottom: 0 !important;
            color: rgba($white, 0.7);
            text-align: center;
          }
          h5 {
            font-family: "Roboto", sans-serif;
            color: $white;
          }
        }
        .view-trip {
          display: flex;
          justify-content: center;
          align-items: center;
          text-align: center;
          font-weight: 600;
          font-size: 18px;
          border-top: 2px solid $white;
          width: 100%;
          padding: 5px 18px;
          margin-bottom: 0px;
          cursor: pointer;
        }
      }
    }
  }
  .ebc-heli {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.35), rgba(0, 0, 0, 0.5)),
      url(../../../image/Everest/EBC-Heli/everestHeliTour.jpg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
  }
  .ebc-heli-trek {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.35), rgba(0, 0, 0, 0.5)),
      url(../../../image/Everest/ebcHeliTrek/19.jpg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
  }
  .manaslu-trek {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.35), rgba(0, 0, 0, 0.5)),
      url(../../../image/Manaslu/manasluTrek/12v2.jpg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
  }
  .abc-trek {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.35), rgba(0, 0, 0, 0.5)),
      url(../../../image/Annapurna/annapurna2.jpg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    -webkit-background-size: cover;
  }
  .gokyo-trek {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.35), rgba(0, 0, 0, 0.5)),
      url(../../../image/Everest/gokyoLake.jpg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
  }
  .annapurna-circuit-tilicho-lake-trek {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.35), rgba(0, 0, 0, 0.5)),
      url(../../../image/Annapurna/AnnapurnaCircuitTilicho/16.jpg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
  }

  .buddhist-pilgrimage-tour {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.35), rgba(0, 0, 0, 0.5)),
      url(../../../image/BuddhistPilgriamge/swayambhu.jpg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
  }
  .mardi-himal-base-camp-trek {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.35), rgba(0, 0, 0, 0.5)),
      url(../../../image/MardiHimalTrek/mardiHimal/23.jpg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
  }

  .island-peak-climbing {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.35), rgba(0, 0, 0, 0.5)),
      url(../../../image/IslandPeakClimbing/islandPeakClimbing.jpeg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
  }

  .mera-peak-climbing {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.35), rgba(0, 0, 0, 0.5)),
      url(../../../image/MeraPeakClimbing/meraPeakClimbing3.jpg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
  }
  @media (max-width: 1500px) {
    .trip-hero {
      .trip-box {
        .trip-detail {
          width: 35vw;
          .view-trip {
            width: 35vw;
          }
        }
      }
    }
  }
  @media (max-width: 950px) {
    .trip-hero {
      h3 {
        text-align: center;
        padding-top: 200px;
      }
      .trip-box {
        .trip-detail {
          width: 50vw;
          .view-trip {
            width: 50vw;
          }
        }
      }
    }
  }
  @media (max-width: 576px) {
    .trip-hero {
      width: 100%;
      h3 {
        font-size: 40px;
        padding-top: 200px;
      }
      .trip-box {
        .trip-detail {
          width: 80vw;
          .view-trip {
            width: 80vw;
          }
        }
      }
    }

    .ebc-heli,
    .abc-trek,
    .manaslu-trek,
    .gokyo-trek,
    .annapurna-circuit-tilicho-lake-trek,
    .trip-hero,
    .everest-region-header {
      background-attachment: scroll !important;
      -webkit-background-size: cover;
    }
  }

  .map {
    height: 600px;
    width: 100%;
    background: url(../../../image/Everest/ebc.jpg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }

  .main-modal {
    width: 100%;
    // height: 100%;
    position: -webkit-sticky;
    position: sticky;
    bottom: 50px;
    display: flex;
    justify-content: end;
    padding-right: 140px;
    margin-top: 50px;
    color: $black;
    z-index: 9999;
  }

  .main-modal-map {
    width: 100%;
    position: sticky;
    bottom: 130px;
    display: flex;
    justify-content: end;
    // margin-top: 50px;
    color: $black;
    z-index: 99;
    .modal-map {
      // background: url(../../../image/Everest/ebc.jpg);
      // width: 400px;
      // height: 100px;
      background-size: cover;
      border-radius: 1rem;
      img {
        max-width: 350px;
      }
    }
  }

  @media (max-width: 900px) {
    .main-modal {
      bottom: 0;
      padding-right: 0 !important;
    }
    .main-modal-map {
      padding-right: 0 !important;
      bottom: 0;
      position: relative;
      margin-top: 10px;
      .modal-map img {
        max-width: 100vw;
      }
    }
  }

  .detail-header {
    background: url(../../../image/1.jpg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 100vh;
    width: 100%;
  }

  .trip-detail-sidebar {
    &-1 {
      color: $black;
      ul {
        list-style-type: none;
      }
    }
  }
  .trip-details-next {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 15px 15px;
    font-size: 1.2rem;
    color: $black;
    position: relative;
    z-index: 99;
    .trip-fact {
      .facts {
        .fact {
          display: flex;
          flex-direction: row;
          font-size: 1.45rem;
          font-weight: 500;
          h1 {
            // font-family: "Anton", sans-serif;
            font-family: "Roboto", sans-serif;
            font-size: 42px;
            // letter-spacing: 0px;
            // line-height: 0.95;
            font-weight: 800;
            color: $primaryColor;
            highlight {
              // font-family: "Anton", sans-serif;
              font-family: "Fredoka one";
              font-size: 3rem;
              // letter-spacing: 0px;
              line-height: 0.95;
              color: $primaryColor;
            }
          }
          img {
            width: 100%;
          }
        }
      }
    }
    .big-title {
      // font-family: "Anton", sans-serif;
      // font-family: "Bebas Neue", cursive;
      font-family: "Roboto", sans-serif;
      font-size: 22px;
      font-weight: 800;
      // line-height: 1.1;
      color: $primaryColor;
    }
    .title-content {
      font-family: "Roboto", sans-serif;
      font-size: 18px;
      font-weight: 400;
      text-align: justify;
      // letter-spacing: 0px;
      line-height: 1.9;
      color: #444;
      text-size-adjust: 100%;
    }
    .long-img {
      height: 60vh;
      width: 100%;
      object-fit: cover;
    }
    .short-img {
      height: 60vh;
      width: 100%;
      object-fit: cover;
    }
  }

  //trip-detail page//
  .trip-sidebar {
    background-color: $white;
    border: 1px solid $black;
    ul li a {
      color: $black;
    }
  } //trip-detail page//

  //everest region trip card//

  .trip-card {
    color: $black;
    img {
      object-fit: cover;
      height: 300px;
      width: 100%;
      &:hover {
        transform: scale(1.02);
        transition: 0.3s ease-in-out;
        cursor: pointer;
      }
    }
  }

  //everest region trip card ends//

  @media (max-width: 600px) {
    .trip-fact {
      .facts {
        .fact {
          display: flex;
          flex-direction: row;
          // font-size: 1.45rem;
          // font-weight: 500;

          h1 {
            // font-family: "Anton", sans-serif;
            font-family: "Fredoka one";
            font-size: 22px !important;
            // letter-spacing: 0px;
            // line-height: 0.95;
            font-weight: 800;
            color: $primaryColor;

            highlight {
              // font-family: "Anton", sans-serif;
              font-family: "Fredoka one";
              font-size: 3rem;
              // letter-spacing: 0px;
              line-height: 0.95;
              color: $primaryColor;
            }
          }
        }
        .big-title {
          // font-family: "Anton", sans-serif;
          // font-family: "Bebas Neue", cursive;
          font-family: "open sans";
          font-size: 22px;
          font-weight: 800;
          // line-height: 1.1;
          color: $primaryColor;
        }
        .title-content {
          font-family: "open sans";
          font-size: 18px;
          font-weight: 400;
          text-align: justify;
          // letter-spacing: 0px;
          line-height: 1.9;
          color: #444;
          text-size-adjust: 100%;
        }
      }
    }

    .trip-hero {
      position: relative;
      // height: 100vh;
      // background: linear-gradient(0deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
      //   url(../../../image/Everest/ebctrek.jpeg);
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      background-attachment: fixed;
      .main-title {
        font-family: "Courier Prime", monospace;
        font-size: 1.5rem !important;
        letter-spacing: 1px;
        font-weight: 400;
        text-align: center;
        color: $white;
      }
    }
  }
}
