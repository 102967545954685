.trip-info {
  h4,
  h5 {
    color: $primaryColor;
    font-family: "Roboto", sans-serif;
  }
}

.trip-modal-title {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  color: #fff;
}

.photo-caption {
  font-size: 14px;
  margin: 0 !important;
}
