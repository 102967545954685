.header-image {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.35), rgba(0, 0, 0, 0.5)),
    url(../../../image/banner_all.jpg);
  background-size: cover;
  background-position: center center;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 400px;
  width: 100%;
  color: $white;
  font-size: 2.5rem;
  font-weight: 900;
}

.whatsapp-icon {
  img {
    height: 30px;
    width: auto;
    position: fixed;
    bottom: 5px;
    left: 30px;
    &:hover {
      cursor: pointer !important;
      transform: scale(1.4);
      transition: 0.3s ease-in-out;
    }
  }
  .instaImg img {
    left: 70px;
    height: 29px;
  }
}

.to-the-top a {
  position: fixed;
  bottom: 50px;
  right: 30px;
  color: #ffd300;
  font-size: 30px;
  &:hover {
    color: #000;
  }
}

@media (max-width: 600px) {
  .whatsapp-icon {
    img {
      height: 19px;
      width: auto;
      position: fixed;
      bottom: 15px;
      left: 10px;
    }
    .instaImg img {
      left: 35px;
      height: 19px;
    }
  }

  .to-the-top {
    display: none;
    a {
      position: fixed;
      bottom: 110px;
      right: 30px;
      color: #b4adad;
      font-size: 30px;
      &:hover {
        color: #000;
      }
    }
  }
}
