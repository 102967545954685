// .trip-card {
//   img {
//     object-fit: cover;
//     height: 300px;
//     width: auto;
//   }
//   &-review {
//     display: flex;
//   }
//   &-review,
//   &-review-text {
//     align-items: center !important;
//     margin-top: -15px;
//     padding-left: 10px;
//   }
// }
.trip-card {
  &-image {
    overflow: hidden !important;
    -webkit-box-shadow: 0 10px 6px -6px #777;
    -moz-box-shadow: 0 10px 6px -6px #777;
    box-shadow: 0 10px 6px -6px #777;
    img {
      object-fit: cover;
      height: 300px;
      width: 100%;
      &:hover {
        transform: scale(1.1);
        transition: transform 0.5s ease;
        cursor: pointer;
      }
    }
  }

  &-review {
    display: flex;
    &-icon {
      color: #ffd301;
      &:hover {
        color: #f1ba00;
      }
    }
    &-number {
      margin: 4px 0 0 0;
    }
  }
  // &-review,
  // &-review-text {
  //   align-items: center !important;
  //   margin-top: -15px;
  //   padding-left: 10px;
  // }
}

// @media (max-width: 567px) {
//   .tripCard {
//     flex-direction: column !important;
//   }
// }
