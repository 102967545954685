.trip-table {
  thead th {
    font-size: 18px;
  }
  th,
  td {
    font-size: 16px;
    font-weight: 600;
  }
}
