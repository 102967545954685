@import url("https://fonts.googleapis.com/css2?family=Anton&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Courier+Prime:ital,wght@0,400;0,700;1,400;1,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Fredoka+One&display=swap");

* {
  margin: 0;
  // font-family: "Roboto", sans-serif;
  // font-family: "Anton", sans-serif;
  // font-family: "Courier Prime", monospace;
  // font-family: "Bebas Neue", cursive;
  // font-family: "Rubik", sans-serif;
  //   font-family: sans-serif;
  font-family: "Roboto", sans-serif;
  // letter-spacing: 1px;
  ::-webkit-scrollbar {
    width: 10px;
  }
  a {
    text-decoration: none !important;
  }
}
::-webkit-scrollbar-track {
  background-color: $white;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(rgba(152, 151, 151, 0), 0.7);
  border-radius: 5px;
}

.first-modal-btn {
  width: 60%;
}

.hover-underline-animation-white {
  display: inline-block;
  position: relative;
  color: $black;
}

.hover-underline-animation-white:after {
  content: "";
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: $black;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}

.hover-underline-animation-white:hover:after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

.hover-underline-animation-black {
  display: inline-block;
  position: relative;
  color: $white;
}

.hover-underline-animation-black:after {
  content: "";
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: $white;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}

.hover-underline-animation-black:hover:after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

@mixin hideScrollbar {
  background-color: red;
  &::-webkit-scrollbar {
    width: 0 !important;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.title {
  font-size: 22px;
  font-weight: 800;
  color: $primaryColor;
  font-family: "Roboto", sans-serif;
  &-1 {
    font-size: 3em;
    font-weight: 800;
    color: $primaryColor;
    font-family: "Roboto", sans-serif;
  }
}

.content {
  font-family: "roboto", sans-serif;
  font-size: 18px;
  font-weight: 400;
  text-align: justify;
  // letter-spacing: 0px;
  line-height: 1.9;
  color: #444;
  text-size-adjust: 100%;
  margin-right: 10px;
}

.book-now-menu {
  .MuiButton-label {
    font-size: 16px;
    font-weight: 800;
    &:hover {
      color: #fff;
      border-bottom: 2px solid #fff;
    }
  }
  .MuiButton-root {
    color: #fff;
    font-weight: 800;
    &:hover {
      color: #fff;
      border-bottom: 2px solid #fff;
    }
  }
  .MuiPaper-root {
    top: 0 !important;
    ul li a {
      color: $primaryColor;
    }
  }
}

.MuiPaper-root {
  ul li a {
    color: $primaryColor;
  }
}

@media (max-width: 567px) {
  .MuiPaper-root {
    top: 0 !important;
    ul li a {
      color: $primaryColor;
    }
  }
}
