@mixin btn($right, $top) {
  position: absolute;
  width: 60px;
  height: 60px;
  top: $top;
  right: $right;
  transition-duration: 0.5s;
}

@mixin icon($height, $width) {
  transition-duration: 0.5s;
  position: absolute;
  height: $height;
  width: $width;
  top: 30px;
  background-color: $white;
  @media (max-width: 576px) {
    top: 60px;
  }
}

@mixin icon-before($height, $width, $top) {
  transition-duration: 0.5s;
  position: absolute;
  width: $width;
  height: $height;
  background-color: $white;
  content: "";
  top: $top;
}

@mixin icon-after($height, $width, $top) {
  transition-duration: 0.5s;
  position: absolute;
  width: $width;
  height: $height;
  background-color: $white;
  content: "";
  top: $top;
}
.nav-main {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0 50px;
  .btns {
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    @include btn(12px, 0px);

    @media (max-width: 640px) {
      @include btn(0px, 0px);
    }
    .icon-name {
      color: $white;
      font-size: 18px;
      @include btn(100px, 0px);

      @media (max-width: 640px) {
        @include btn(80px, 30px);
      }
    }
    .icon {
      display: flex;
      flex-direction: row;
      .icon-left {
        @include icon(2px, 15px);

        &:before {
          @include icon-before(2px, 15px, -10px);
        }

        &:after {
          @include icon-after(2px, 15px, 10px);
        }

        &:hover {
          cursor: pointer;
        }
      }

      .icon-right {
        @include icon(2px, 15px);
        left: 15px;

        &:before {
          @include icon-before(2px, 15px, -10px);
        }

        &:after {
          @include icon-after(2px, 15px, 10px);
        }
      }

      &.open {
        .icon-left {
          transition-duration: 0.5s;
          background: transparent;

          &:before {
            transform: rotateZ(45deg) scaleX(1.4) translate(2px, 2px);
          }

          &:after {
            transform: rotateZ(-45deg) scaleX(1.4) translate(2px, -2px);
          }
        }

        .icon-right {
          transition-duration: 0.5s;
          background: transparent;

          &:before {
            transform: rotateZ(-45deg) scaleX(1.4) translate(-2px, 2px);
          }

          &:after {
            transform: rotateZ(45deg) scaleX(1.4) translate(-2px, -2px);
          }
        }
      }
      &:hover {
        cursor: pointer;
      }
    }
  }
  .logo {
    // text-align: center;
    // display: flex;
    // justify-content: center;
    // color: $white;
    // font-size: 2rem;
    // width: 50px;
    // height: 50px;

    a {
      display: flex;
      justify-content: center;
      color: $white;
      width: 50px;
      height: 50px;
      img {
        width: 150px;
        height: 150px;
        margin: -30px 0 0 20px;
      }
    }
    @media (max-width: 576px) {
      font-size: 1.7rem;
    }
  }
}
.nav-main-fixed {
  position: fixed;
  top: 20px;
  color: $white;
}
.top-nav {
  background-color: $black;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: -5;
  display: flex;
  flex-direction: column;
  align-items: center;
  .nav-options {
    color: $white;
    margin-top: 80px;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 1px;
    width: 100%;
    padding: 10px 0px;
    .nav-option {
      // width: 20%;
      // padding: 10px 0;
      line-height: 50px;
    }
  }
  .nav-trips {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 85%;
    margin-bottom: 40px;
    .nav-trip {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 25%;
      border: $white solid 1.5px;
      &-image {
        overflow: hidden;
      }
      .nav-img {
        width: 85%;
        height: 220px;
        object-fit: cover;
        margin-top: 30px;
        margin-bottom: 10px;
        transition: transform 0.5s ease;
        &:hover {
          transform: scale(1.1);
        }
      }
      .nav-desc {
        color: $white;
        margin-bottom: 30px;
      }
    }
  }
}

.top-nav-close {
  top: -1000px;
}

@media (max-width: 576px) {
  .top-nav {
    display: flex;
    flex-direction: column-reverse;
    .nav-options {
      margin-top: 0px;
    }
    .nav-trips {
      display: flex;
      flex-direction: column;
      margin-bottom: 0;
      margin-top: 150px;
      .nav-trip {
        width: 100%;
        padding-top: 10px;
        .nav-img {
          display: none;
        }
      }
    }
  }
  .nav-main .logo a img {
    width: 100px;
    height: 100px;
    margin: 0;
  }
}
