.footer {
  background-color: $white;
  background-image: url(../../../image/footer1.png);
  background-size: cover;
  background-position: center;
  color: $black;
  padding: 40px 0 5px 0;
  list-style: none;
  scroll-snap-align: start;
  scroll-snap-align: end;
  .footer-row {
    display: flex;
    justify-content: space-between;
    a {
      color: $primaryColor;
      &:hover {
        color: #0b707e;
      }
    }
    .activities,
    .useful-link,
    .destinations {
      ul {
        list-style: none;
        padding: 0;
        margin: 0;
        li {
          text-decoration: none;
          color: $black !important;
          font-size: 18px;
          line-height: 2;
          a {
            text-decoration: none;
            color: #292929;
            &:hover {
              color: #0b707e;
            }
          }
        }
      }
    }

    p {
      line-height: 2;
      font-family: "open sans";
      font-size: 18px;
    }
  }

  &-content {
    width: fit-content !important;
    text-align: left !important;
  }
  .copyright {
    text-align: center;
    font-size: 14px !important;
    display: flex;
    justify-content: center;
    flex-direction: column;
    p {
      // letter-spacing: 0.5px;
      margin-bottom: 0;
      line-height: 2;
    }
    a {
      text-decoration: none;
      color: $primaryColor;
    }
    a:hover {
      text-decoration: underline;
      color: #0b707e;
    }
  }

  &-title {
    font-size: 22px;
    color: $primaryColor;
    font-family: "open sans";
  }

  &-headquater {
    a {
      text-decoration: none;
      color: $primaryColor;
      &:hover {
        color: #0b707e;
      }
    }
  }
}

.affiliated-img {
  ul {
    padding: 0;
  }
  ul li {
    list-style-type: none;
    display: inline;
    margin-right: 20px;
  }
  img {
    height: 55px;
    width: auto;
  }
}

.payment-img img {
  height: 18px;
  width: auto;
}

.footer-social-icon {
  gap: 5px;
  .fbIcon a {
    font-size: 18px;
    color: $primaryColor;
    &:hover {
      color: #3b5998;
      cursor: pointer;
    }
  }
  .twitterIcon a {
    font-size: 18px;
    color: $primaryColor;
    &:hover {
      color: #00acee;
    }
  }
  .instaIcon a {
    color: $primaryColor;
    font-size: 18px;
    &:hover {
      color: #8a3ab9;
    }
  }

  .linkedinIcon {
    font-size: 18px;
    color: $primaryColor;
    &:hover {
      color: #0072b1;
    }
  }
}

.global-title {
  font-size: 28px;
  // text-transform: uppercase;
  font-family: "open sans";
}

.tripadvisor-footer {
  display: flex;
  justify-content: center;
  // img {
  //   width: 200px;
  // }
  ul {
    padding: 0;
  }
  li {
    list-style: none;
  }
}

@media (max-width: 576px) {
  .footer-social-icon {
    .col-1 {
      padding: 0 !important;
    }
  }

  .tripadvisor-widget-custom {
    #CDSWIDSSP {
      width: 100% !important;
    }
  }

  .footer {
    .footer-row {
      justify-content: center !important;
    }
    &-content {
      text-align: center !important;
    }
  }
}

@media (min-width: 576px) {
  .trip-advisor-small {
    display: none;
  }
}

@media (max-width: 900px) {
  .affiliated-img {
    img {
      height: 30px;
      width: auto;
    }
  }

  .footer-social-icon {
    gap: 10px;
    span {
      font-size: 20px;
    }
  }

  .footer-row {
    .footer {
      &-affiliated,
      &-connect {
        margin: 30px auto !important;
        text-align: center !important;
      }
    }
  }
}
