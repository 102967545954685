// .main-hero {
//   scroll-snap-type: y none;
//   overflow-y: scroll;
//   height: 100vh;
//   transition: 0.2s;
// .hero {
//   background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.6)), url(../../image/Everest/Gosaikunda.1.jpg);
//   background-size: cover;
//   background-position: center;
//   background-repeat: no-repeat;
//   display: flex;
//   flex-direction: column;
//   justify-content: center;
//   height: 100vh;
//   transition: 0.2s;
.hero {
  video {
    height: 100vh;
    width: 100%;
    object-fit: cover;
    // -webkit-object-fit: cover;
  }
  background: linear-gradient(0deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.3)),
    url(../../image/Langtang/langtang1.jpeg);
  background-size: cover;
  --webkit-background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
  width: 100%;
  scroll-snap-align: center;
  scroll-behavior: smooth;
  background-attachment: fixed;
  background-blend-mode: darken;
  background-repeat: no-repeat;
  h3 {
    font-family: "Anton", sans-serif;
    line-height: 1;
    text-align: center;
    font-size: 100px;
    color: $white;
    display: block;
    transition: 0.25s;
    // margin: 230px 0;
  }

  .home-down-icon {
    color: $black;
    font-size: 26px;
    cursor: pointer;
  }

  @media (max-width: 576px) {
    h3 {
      font-size: 50px;
    }
  }
}

.sub-hero {
  position: relative;
  scroll-snap-align: start;
  height: 100vh;
  width: calc(100vw - 70px);
  margin: 5px auto;
  border-top: 35px solid $white;
  border-bottom: 35px solid $white;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  color: $black;
  h3 {
    font-family: "Anton", sans-serif;
    font-size: 3rem;
    color: $white;
    padding: 20px 40px 0 40px;
    z-index: 999999999;
  }
  .trip-box {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100%;
    position: absolute;
    top: 0;
    color: $white;
    .trip-detail {
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4));
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      border: 2px solid $white;
      border-bottom: 0;
      color: $white;
      width: 20vw;
      // height: 25vh;
      // padding: 20px 20px 0 20px;
      .main-title {
        font-family: "Roboto", sans-serif;
        font-size: 1.5rem;
        letter-spacing: 1px;
        font-weight: 400;
        text-align: center;
      }
      .activity {
        padding: 0 20px;
        text-align: center;
        p {
          font-family: "Courier Prime", monospace;
          margin-bottom: 0 !important;
          color: rgba($white, 0.7);
        }
        h5 {
          font-family: "Courier Prime", monospace;
        }
      }
      .view-trip {
        display: flex;
        justify-content: center;
        color: $white;
        align-items: center;
        text-align: center;
        font-weight: 600;
        font-size: 18px;
        border: 2px solid;
        border-left: 0;
        border-right: 0;
        width: 100%;
        padding: 5px 18px;
        margin-bottom: 0px;
        cursor: pointer;
      }
    }
  }
}
@media (max-width: 1500px) {
  .sub-hero {
    .trip-box {
      .trip-detail {
        width: 35vw;
      }
    }
  }
}
@media (max-width: 950px) {
  .sub-hero {
    h3 {
      text-align: center;
      padding-top: 200px;
    }
    .trip-box {
      .trip-detail {
        width: 50vw;
      }
    }
  }
}
@media (max-width: 576px) {
  .hero {
    background-attachment: scroll !important;
    --webkit-background-size: cover;
    // --webkit-background-repeat: no-repeat;
    // height: 400px;
  }
  .sub-hero {
    width: 100%;
    border: 0;
    h3 {
      font-size: 40px;
      padding-top: 50px;
    }
    .trip-box {
      .trip-detail {
        width: 80vw;
      }
    }
  }
}

.hero1 {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
    url(../../image/Everest/EBCTrek/8.jpg) right;
  background-size: cover;
}

.hero2 {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url(../../image/Everest/EBC-Heli/everestHeliTour.jpg);
  background-size: cover;
  background-position: center;
}
.hero3 {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
    url(../../image/Annapurna/AnnapurnaCircuitTilicho/12.jpg);
  background-size: cover;
  background-position: bottom center;
}
.hero4 {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
    url(../../image/Everest/EBC-Heli/HeliTourGallery/amt1a.jpg);
  background-size: cover;
  background-position: bottom center;
}
.hero5 {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
    url(../../image/Manaslu/manasluTrek/12v3.jpg);
  background-size: cover;
  background-position: bottom center;
}
.hero6 {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
    url(../../image/MeraPeakClimbing/meraPeakClimbing3.jpg);
  background-size: cover;
  background-position: bottom bottom;
}
.hero7 {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),
    url(../../image/MardiHimalTrek/mardiHimal/3.jpg);
  background-size: cover;
  background-position: center center;
}
.hero8 {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),
    url(../../image/IslandPeakClimbing/islandPeakClimbing.jpeg);
  background-size: cover;
  background-position: center center;
}

.first-modal {
  display: flex;
  max-width: 600px;
  justify-content: center;
  .modal-content {
    .first-modal-body {
      display: flex;
      flex-direction: column;
      justify-content: center !important;
      align-items: center !important;
      .first-modal-text {
        padding-bottom: 0px;
        font-size: 1.1rem;
        line-height: 1.2;
      }
      &-btn {
        border-radius: 9px;
        font-size: 1.2rem;
        font-weight: 600;
        letter-spacing: 2px;
        padding: 10px 0;
        width: 60%;
      }
    }
  }
}

.luxury-package-info {
  Button {
    margin: 0;
    padding: 0;
    color: rgba(248, 249, 250, 0.7);
    min-width: 0;
    &:hover {
      color: #fff;
    }
  }
}

.home {
  &-departure-btn {
    border: 2px solid #fff !important;
    color: #fff !important;
    &:hover {
      border: none;
      background-color: #fff !important;
      color: #202222 !important;
    }
  }

  &-review-slider {
    .content {
      width: 70%;
      margin: 0 auto;
    }
    .carousel-control-next,
.carousel-control-prev /*, .carousel-indicators */ {
      filter: invert(100%);
    }
    // &-google-review {
    //   color: #ffc107;
    // }
  }
}

.sister-company {
  margin: 60px 0;
  img {
    filter: gray;
    -webkit-filter: grayscale(1);
    -webkit-transition: all .8s ease-in-out; 
    width: 150px;
    &:hover {
      filter: none;
      -webkit-filter: grayscale(0);
      -webkit-transform: scale(1.01);
      transform: scale(1.06);
      transition: all .2s ease-in-out;
    }
  }
}
@media (max-width: 567px) {
  .sister-company {
    img {
      filter: none;
      &hover {
        filter: none;
      }
    }
  }
}