.modals {
  display: flex;
  flex-direction: column;
  width: 350px;
  // padding: 15px 30px;
  border: $black solid 2px;
  position: -webkit-sticky;
  position: sticky;
  bottom: 100px;
  background-color: $white;
  color: $black;
  .modal-head {
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    justify-content: space-between;
    padding: 5px 30px;
    padding-left: 0;
    border-bottom: $black solid 1px;
    .modal-head-title {
      display: flex;
      align-items: center;
      font-family: "Courier Prime", monospace;
      width: 100%;

      .head-title {
        width: 100%;
        text-align: center;
      }
    }
    .iclose {
      display: flex;
      justify-content: end;
    }
  }
  .modal-body {
    display: flex;
    flex-direction: column;
    padding: 15px 30px;
    border-bottom: $black solid 1px;
    font-size: 16px;
    .modal-content {
      .modal-title {
        font-family: "Roboto", sans-serif;
        color: $primaryColor;
        font-size: 16px;
      }
      .modal-text {
        font-family: "Roboto", sans-serif;
        font-size: 16px;
      }
    }
  }
  .modal-body-expand {
    display: flex;
    flex-direction: column;
    height: 400px;
    overflow-y: auto;
    .modal-content {
      display: flex;
      flex-direction: row;
      margin-bottom: 20px;

      .modal-title {
        width: 20%;
        font-size: 18px;
        font-family: "open sans";
      }
      .modal-text {
        width: 80%;
        font-size: 18px;
        font-family: "open sans";
        line-height: 1.9;
      }
    }
  }
  .modal-book {
    cursor: pointer;
    bottom: 0;
    padding: 10px 30px;
    font-weight: 600;
    background: $primaryColor;
    color: #fff !important;
    .link {
      color: $black;
    }
  }
}

.expand {
  width: 80%;
  height: 80vh;
}

.first-modal {
  &-text {
    font-family: "open sans";
    font-size: 22px;
    line-height: 1.9;
  }
  &-btn {
    background-color: $primaryColor !important;
    border: none !important;
    a {
      color: #fff !important;
    }
  }
  &-btn:hover {
    background-color: #3e3e3e !important;
  }
}

.departure-date-modal-trip-info {  
  color: #fff;
  &:hover {
    color: #ffd301;
  }
}

@media (max-width: 900px) {
  .modals {
    width: 100%;
    border-radius: 15px 15px 0 0;
    .modal-head {
      padding: 8px 30px;
    }
    .modal-body {
      padding: 8px 30px;
      .modal-content {
        display: none;
        &:first-child {
          display: flex;
        }
      }
    }
    .modal-book {
      padding: 10px 30px;
      text-align: center;
    }
    .modal-body-expand {
      .modal-content {
        display: flex;
        flex-direction: column;
        .modal-title {
          width: 100%;
        }
        .modal-text {
          width: 100%;
        }
      }
    }
  }
  .expand {
    height: 80vh;
  }
  .first-modal-text {
    font-family: "open sans";
    font-size: 18px;
    line-height: 1.5;
  }
}

.luxury-package-modal{
  z-index: 999999 !important;
}

@media (min-width: 567px) and (max-width: 768px) {
  .modal-content {
    background: transparent !important;
    margin-top: 30px;
    .popupcard {
      display: flex !important;
      flex-direction: column !important;
      margin: 0 !important;
      .card {
        width: 50vw !important;
      }
    }
  }
}

@media (max-width: 567px) {
  .modal-content {
    background: transparent !important;
  }
}

.luxury-package-modal .modal-dialog .modal-dialog-centered {
  background: none;
}

.luxury-package-modal .modal-content {
  background-color: #fff !important;
  color: #202222 !important;
  text-align: left;
}

.luxury-package-modal-btn {
  background: none;
  border: none;
  color: #fff;
  &:hover {
    color: #ffd301 !important;
  }
}

.departure-date {
  &-modal {
    max-width: 800px !important;
  }
  &-badge {
    padding-right: 20px !important;
  }
}
