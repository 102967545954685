.detail-itinerary {
  .itinerary-content {
    font-family: "Roboto", sans-serif;
    font-size: 18px;
    font-weight: 400;
    text-align: justify;
    // letter-spacing: 0px;
    line-height: 1.9;
    color: #444;
    text-size-adjust: 100%;
    // margin-left: 1.5rem;
  }
  .itinerary-title {
    font-size: 22px;
    font-weight: 800;
    color: $primaryColor;
    font-family: "Roboto", sans-serif;
    text-transform: uppercase;
  }
  h4 {
    color: $primaryColor;
  }
  .itinerary {
    &-icon-row {
      display: flex;
      justify-content: space-around;
      align-items: center;
      // border: 2px dashed #202222;
      color: #202222;
      margin: 0 0 60px 0;
      padding: 10px 0;
    }
    &-icon {
      display: flex;
      align-items: center;
      &-title {
        display: flex;
        flex-direction: column;
        // margin-left: 12px;
        font-weight: 500;
        // color: #15626E;
      }
    }
    &-content {
      line-height: 2;
    }
    &-header {
      color: #15626e;
    }
  }
}

.itinerary-image {
  margin-bottom: 8px;
  img {
    height: auto;
    width: 100%;
    margin-bottom: 8px;
  }
  &-caption {
    margin: 30px 0;
  }
}

@media (max-width: 600px) {
  .itinerary-content {
    margin: 0 !important;
  }
}

.ebc-heli-itinerary {
  display: flex;
  flex-direction: column;
}
