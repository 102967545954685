$facebook: #3b5998 !default;
$twitter: #00aced !default;
$linkedin: #4875b4 !default;
$github: #4183c4 !default;
// $primaryColor: #16626d;
$primaryColor: #202222;
$secondaryColor: #c57b24;


$black: rgb(41, 41, 41);
$white: #f8f9fa;


$primary: #0d6efd;
$success: #198754;
$danger: #dc3545;
$warning: #ffc107;
$info: #0dcaf0;
$purple: rebeccapurple;
$gray-200: #e9ecef;
@mixin fade-updown($name, $zeroy) {
  @keyframes #{$name} {
    0% {
      opacity: 0;
      transform: translateY($zeroy + px);
    }
    100% {
      opacity: 1;
      transform: translateY(0px);
    }
  }
}

@mixin fade-leftright($name, $zeroy) {
  @keyframes #{$name} {
    0% {
      opacity: 0;
      transform: translateX($zeroy + px);
    }
    100% {
      opacity: 1;
      transform: translateX(0px);
    }
  }
}
