.about-page {
  background: #202222;
  padding: 60px 0;
  h4,
  h2 {
    color: #fff;
    margin-top: 60px;
  }
  p {
    color: $white;
  }
}
.photo-caption {
  font-size: 14px !important;
  color: #666666;
}

.team {
  display: flex;
  justify-content: space-evenly;
  &-card {
    border: none !important;
    width: 300px;
    margin-top: 30px;
    a {
      text-decoration: none;
      color: $primaryColor;
    }
    &-img {
      overflow: hidden;
      height: 300px;
      img {
        transition: transform 0.5s ease;
      }
      img:hover {
        transform: scale(1.1);
      }
    }
    .card-text {
      line-height: 2;
    }
  }
  &-profile {
    align-items: center;
    display: flex;
    justify-content: space-between;
    img {
      height: 300px;
      object-fit: cover;
      border-radius: 15px;
      width: 100%;
    }
    a {
      text-decoration: none;
      color: $primaryColor;
    }
  }
}

.team-card {
  .card-text {
    color: $black;
  }
}

@media (max-width: 576px) {
  .team-card {
    width: 100% !important;
    margin: 30px 0 !important;
  }
}
