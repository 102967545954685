.trip-fact {
  &-title {
    font-size: 16px;
  }
  &-subtitle {
    font-size: 14px;
    color: $primaryColor;
  }
  &-icon {
    font-size: 30px;
    color: #0b707e;
  }
}
