.nav {
  position: relative;
  top: 40px;
  z-index: 999999;
  width: 100%;
  max-width: 100%;
}

.main-body {
  position: absolute;
  top: 0;
  background-color: $white;
  width: 100%;
}

.header {
  background-color: $white;
  color: $black;
}

.link {
  text-decoration: none;
  color: #fff;
  &:hover {
    color: #fff;
  }
}
