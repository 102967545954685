.everest-region-header {
  background: linear-gradient(180deg, rgb(0, 0, 0), rgba(0, 0, 0, 0)),
    url(../../../image/Everest/EBCTrek/8.jpg);
  width: 100%;
  height: 100vh;
  background-size: cover;
  background-position: center center;
  -webkit-background-size: cover;
  position: relative;
}

.annapurna-region-header {
  background: linear-gradient(180deg, rgb(0, 0, 0), rgba(0, 0, 0, 0)),
    url(../../../image/Annapurna/annapurna2.jpg);
  width: 100%;
  height: 100vh;
  background-size: cover;
  background-position: top center;
  -webkit-background-size: cover;
  position: relative;
}
