.contact {
  display: flex;
  justify-content: space-between;
  padding: 60px 0 30px 0;
  color: $black;
  a {
    color: $black;
    text-decoration: none;
  }

  h3 {
    text-align: center;
    color: $white;
  }
}
