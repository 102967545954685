.trip-navbar {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
  // background-color: rgb(255, 244, 207) !important;
  background-color: #202222;
  color: $black;
  position: sticky;
  top: 0;
  z-index: 99999;
  align-items: center;
  height: 50px;
  p {
    font-size: 16px;
    font-weight: 800;
    font-family: "Roboto", sans-serif;
    color: #fff;
    line-height: 1.9;
    margin-bottom: 0;
    cursor: pointer;
  }
  p:hover {
    color: #fff;
    border-bottom: 2px solid #fff;
  }
  span {
    font-size: 1.5rem;
    padding: 0 10px;
    color: #fff;
  }
  .active {
    color: #fff;
    border-bottom: 2px solid #fff;
  }
}
@media (max-width: 576px) {
  .trip-navbar {
    z-index: 999;
    gap: 30px;
    p {
      font-size: 12px !important;
    }
    span {
      display: none;
    }
  }
}
