.overview {
  color: $black;
  font-size: 18px;
  .overview-column {
    display: flex;
    flex-direction: row;
  }
  ul li {
    font-family: "Roboto", sans-serif;
    line-height: 2 !important;
  }
  ol li {
    font-family: "Roboto", sans-serif;
    line-height: 2 !important;
  }

  &-title {
    font-size: 22px;
    font-weight: 800;
    color: $primaryColor;
    font-family: "Roboto", sans-serif;
  }

  &-content {
    font-family: "roboto", sans-serif;
    font-size: 18px;
    font-weight: 400;
    text-align: justify;
    // letter-spacing: 0px;
    line-height: 1.9;
    color: #444;
    text-size-adjust: 100%;
    margin-right: 10px;
  }

  .accordion {
    &-title {
      font-size: 18px;
      font-weight: 500;
      color: $primaryColor;
      font-family: "Roboto", sans-serif;
    }
    &-button:not(.collapsed) {
      background-color: #f8f9fa;
      color: $primaryColor;
    }
    &-button {
      color: $primaryColor;
      &:focus {
        box-shadow: none;
      }
    }
  }

  .trip-overview-accordion {
    .accordion {
      background: #f8f9fa;
    }
  }

  .overview-sidebar {
    position: -webkit-sticky;
    position: sticky;
    color: #000;
    top: 50px;
    padding: 1rem 2rem;
    border-radius: 8px;
    margin-bottom: 20px;
    border: 2px solid $primaryColor;
    box-shadow: 5px 10px #4a474726;
    &-days {
      color: #000;
      font-size: 25px;
    }
    &-destination {
      color: #000;
    }
    .price-text {
      // font-size: 25px;
      color: $primaryColor;
    }
  }
  .badge {
    background-color: $primaryColor !important;
    color: #fff;
  }
  .btn-book-now {
    border: 2px solid $primaryColor;
    color: $primaryColor;
    &:hover {
      background-color: $primaryColor;
    }
  }
  &-outline-itinerary {
    list-style: none;
    padding: 0;
    align-items: baseline;
    display: flex;
    gap: 10px;
    // &-days {
    //   display: flex;
    //   align-items: center;
    //   color: #0b707e;
    // }
  }
}

.group-discount-table {
  border: 2px dashed;
  width: 100%;
  p {
    width: max-content;
    padding: 5px;
  }
  td,
  th {
    padding: 5px;
    font-size: 16px;
    font-weight: 400;
  }
}

.book-btn {
  background: $primaryColor;
  padding: 5px 10px;
  color: #fff;
  border-radius: 10px;
  border: none;
  &:hover {
    background-color: #0b454e;
  }
}

@media (max-width: 1000px) {
  .overview {
    ul li {
      font-size: 18px;
    }
    ol li {
      font-size: 18px;
    }
    .overview-column {
      flex-direction: column-reverse;
    }
    &-content {
      font-size: 18px !important;
    }
  }
}

.cost-include,
.cost-exclude,
.trip-highlight {
  padding: 0;
  li {
    list-style: none !important;
  }
}

// .cost {
//   &-include li::before {
//     font-family: "Font Awesome 5 Free";
//     content: "\f058";
//     font-weight: 900;
//     color: $info;
//     margin-right: 10px;
//   }

//   &-exclude li::before {
//     font-family: "Font Awesome 5 Free";
//     content: "\f057";
//     font-weight: 900;
//     color: $danger;
//     margin-right: 10px;
//   }
// }

// .trip-highlight li::before {
//   font-family: "Font Awesome 5 Free";
//   content: "\f058";
//   font-weight: 900;
//   color: $success;
//   margin-right: 10px;
// }
.trip-highlight,
.cost-include,
.cost-exclude {
  span {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: 10px;
    align-items: flex-start;
    .trip-highlight-icon,
    .cost-include-icon,
    .cost-exclude-icon {
      font-size: 20px;
      height: 20px;
    }
  }
  // &-icon {
  //   color: #0097b6;
  // }
}

.trip-highlight-icon {
  color: #0097b6;
}

.cost-include-icon {
  color: $success;
}

.cost-exclude-icon {
  color: $danger;
}

.ebc-heli-outline-itinerary {
  padding: 0;
  ul {
    list-style: none;
  }
  li::before {
    font-family: "Font Awesome 5 Free";
    content: "\f058";
    font-weight: 900;
    color: $primaryColor;
    margin-right: 10px;
    color: grey;
  }
}

@media (max-width: 600px) {
  .cost-include,
  .cost-exclude,
  .trip-highlight {
    li:before {
      margin-right: 3px;
    }
  }
}

.adm-gallery img {
  -webkit-box-shadow: 0 10px 6px -6px #777;
  -moz-box-shadow: 0 10px 6px -6px #777;
  box-shadow: 0 10px 6px -6px #777;
}

.gallery-box {
  height: 90vh !important;
}

@media (max-width: 576px) {
  .gallery-box {
    height: 450px !important;
  }
}
